<template>
    <AppOnePageDropdown
        v-model="value"
        :idValue="idValue"
        placeholder="Selecione a descrição de risco..."
        optionLabel="nome"
        :service="$service"
        :itemSize="itemSize"
        :renderValue="(slotProps) => slotProps.value?.descricaoRisco?.nome"
        :renderOption="(slotProps) => slotProps.option?.descricaoRisco?.nome"
        :filtrosExtras="filtrosExtras"
    >
    </AppOnePageDropdown>
</template>
<script>
import BaseService from '../../../services/BaseService';
export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        idValue: {
            type: Number
        },
        filtrosExtras: {
            type: Object
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new BaseService(`/risco-descricao-risco`);
    }
    
}
</script>
<style lang="">
</style>